const APPS_AUTH_USER = 'apps_auth_user_warms';

export function getAuthUser() {
    //let user = JSON.parse(localStorage.getItem(APPS_AUTH_USER));
    let user = JSON.parse(sessionStorage.getItem(APPS_AUTH_USER));

    if (user) {
        return user;
    } else {
        return null;
    }
}

export function getAuthUserAccessToken() {
    //console.log('===========getAuthUserAccessToken()=========================')
    //console.log(props)
    //let user = JSON.parse(localStorage.getItem(APPS_AUTH_USER));
    let user = JSON.parse(sessionStorage.getItem(APPS_AUTH_USER));

    if (user && user.id) {
        return user.id
    } else {
        return null
    }
}

export function saveAuthUser(user) {
    //localStorage.setItem(APPS_AUTH_USER, JSON.stringify(user));
    sessionStorage.setItem(APPS_AUTH_USER, JSON.stringify(user));
}

export function removeAuthUser() {
    //localStorage.removeItem(APPS_AUTH_USER)
    sessionStorage.removeItem(APPS_AUTH_USER)
}
