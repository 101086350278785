import { GetQuery, PostQuery, Delete, Post } from '../../_attfunc';
//import Dates from '../../_attfunc/dates'
import { SERVER } from '../../_constants'

class PickupPoint{
    getPickupPoint(){
        //console.log('=========PickupPoint: getPickupPoint()=================')
        return GetQuery(SERVER.API.PickupPoint.Lists,{fields:["id","bangunan","block","lat","lng"]})
    }
    count(filters){
        return GetQuery(SERVER.API.PickupPoint.Count,filters)
    }
    register(param){
        return Post(SERVER.API.PickupPoint.Lists, param)
    }
    update(id, param){
        return PostQuery(SERVER.API.PickupPoint.Update, param, {where: {id: id}})
    }
    deletes(id){
        return Delete(SERVER.API.PickupPoint.Lists+'/'+id)
    }
}

export default PickupPoint;
