import { GetQuery } from '../../_attfunc'
import { SERVER } from '../../_constants'
import Dates from '../../_attfunc/dates'

class Session{
    getList(filters){
        return GetQuery(SERVER.API.Session.Lists, filters)
    }
    getFromTo(frm, tto){
        let d_frm = Dates.getUTCDateTimeTZ(frm+' 00:00:00')
        let d_tto = Dates.getUTCDateTimeTZ(tto+' 23:59:59')
        // {"where":{"startTime":{"between":["2019-02-10T00:00:00Z","2019-02-11T23:00:00Z"] } }}
        return GetQuery(SERVER.API.Session.Lists, {where:{startTime:{between: [d_frm,d_tto]}}})
    }
}

export default Session;