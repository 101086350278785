import { CONSTANTS } from '../_constants';
let initState = {data:[]}

export function users(state = initState, action) {
  //console.log(state)
  switch (action.type) {
    case CONSTANTS.USER.LIST_REQUEST:
      return {
        request:true,
        data:[...state.data]
      };
    case CONSTANTS.USER.LIST_SUCCESS:
        let _number = 1
        for (let index = 0; index < action.result.length; index++) {
            action.result[index].number = _number++
        }
      return {
        data:action.result
      };
    case CONSTANTS.USER.LIST_FAILURE:
      if(typeof(state.data)==='undefined'){
        state.message = action.err
        return state
      }
      return {
        message:action.message,
        data:[...state.data]
      };
    case CONSTANTS.USER.REGISTER_REQUEST:
      return {
        request:true,
        data:[...state.data]
      };
    case CONSTANTS.USER.REGISTER_SUCCESS:
      return {
        data:[...state.data, action.result]
      };
    case CONSTANTS.USER.REGISTER_FAILURE:
      return {
        message:action.message,
        data:[...state.data]
      };
      case CONSTANTS.USER.DELETE_REQUEST:
      return {
        request:true,
        data:[...state.data]
      };
    case CONSTANTS.USER.DELETE_SUCCESS:
      let _a = state.data
      for (let index = 0; index < _a.length; index++) {
        const element = _a[index];
        if(element.id === parseInt(action.result.id)){
          _a.splice(index,1)
          break
        }
      }
      return {
        data: _a
      };
    case CONSTANTS.USER.DELETE_FAILURE:
      return {
        message:action.message,
        data:[...state.data]
      };
    case CONSTANTS.USER.SEARCH:
      return  {
        search: true,
        sdata: action.data,
        data: [...state.data]
      }
    case CONSTANTS.CLEAR:
      return initState
    default:
      return state
  }
}