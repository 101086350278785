import { SERVER } from '../_constants';
//import { Post,PostQuery,Patch,Get,GetQuery } from '../_attfunc';
import Dates from '../_attfunc/dates'
import PickupProgress from '../_models/pickupprogress'
import PickkupPoint from '../_models/pickuppoint'
import Tracker from '../_models/tracker'
import { CONSTANTS } from '../_constants'
import { getAuthUserAccessToken } from '../_attfunc/auth'

const _PickupProgress = new PickupProgress()
const _PickkupPoint = new PickkupPoint()
const _Tracker = new Tracker()

var _latest_timestap = ''
//var _totalPickupPoint = 0
var _block_done = []
var _total_complete = 0
var _total_not_complete = 0
var _total_weight = 0.0
var _latest_pick_location_block = ''
var _latest_pick_location = ''
var _latest_pick_img_before = null
var _latest_pick_img_after = null
var _latest_pick_weight = 0.0

export const LiveTrackingActions = {
    getStatus,
    getLatest,
    getLatestLocation,
    getListDonePickToday,
    getLatestTracker
};

async function getStatus(dispatch){
    try {
        _total_weight = 0
        _block_done = []

        const res0 = await _PickkupPoint.count()
        //_totalPickupPoint = res0
        //console.log(res0) // {count:108}
        const res1 = await _PickupProgress.getTodayPickupProgress()
        //console.log(res1) // [{},{}]
        _total_complete = res1.length
        _total_not_complete = res0.count - _total_complete
        
        if(res1.length > 0){
            let id = res1[res1.length-1].id
            let res2 = await _PickupProgress.getPickupProgressPicture(id,'before')
            //console.log(res2)
            for(let i=0;i<res1.length;i++){
                _total_weight += res1[i].afterWeight
                _block_done.push(res1[i].pointBlock)
            }
            _latest_pick_location_block = res1[res1.length-1].pointBlock
            _latest_pick_location = res1[res1.length-1].pointBangunan
            _latest_pick_weight = res1[res1.length-1].afterWeight
            _latest_timestap = res1[res1.length-1].timestamp
            //http://localhost:3005/api/Storages/image_before/files/pickpoint_Sebelum_20190212_012223_1445086417.jpeg
            for(let i=0;i<res2.length;i++){
                if(res2[i].container === 'image_after'){
                    _latest_pick_img_after = SERVER.API.Storage.List+'/image_after/download/'+res2[i].filename+'?access_token='+getAuthUserAccessToken()
                }
                else{
                    _latest_pick_img_before = SERVER.API.Storage.List+'/image_before/download/'+res2[i].filename+'?access_token='+getAuthUserAccessToken()
                }
            }
            //console.log(latest_pick_img_after)
            //console.log(latest_pick_img_before)
        }
        
        let _result = {
            date: Dates.getDates(),
            block_done:_block_done,
            complete:_total_complete,
            pending:_total_not_complete,
            accweight:_total_weight,
            latest_pick_location_block:_latest_pick_location_block,
            latest_pick_location:_latest_pick_location,
            latest_pick_img_before:_latest_pick_img_before,
            latest_pick_img_after:_latest_pick_img_after,
            latest_pick_weight:_latest_pick_weight
        }
        //console.log(_result)
        if(typeof(dispatch)!=='undefined' && dispatch!== null){
            dispatch({ type: CONSTANTS.PICKUP_POINT.SUCCESS, _result })
        }
        else{
            return Promise.resolve(_result)
        }
    } catch (error) {
        //console.log(error)
        if(typeof(dispatch)!=='undefined' && dispatch!== null){
            dispatch({ type: CONSTANTS.PICKUP_POINT.FAILURE, message:error })
        }
        else{
            return Promise.reject(error)
        }
    }
}

function getLatest(dispatch){
    //console.log(_latest_timestap)
    if(typeof(dispatch)!=='undefined' && dispatch!== null){
        _getLatest(function(err, result){
            if(err){
                dispatch({ type: CONSTANTS.PICKUP_POINT.FAILURE, message:err })
                return
            }
            dispatch({ type: CONSTANTS.PICKUP_POINT.SUCCESS, result:result })
        })
    }        
    else{
        return new Promise(function(resolve,reject){
            _getLatest(function(err, result){
                if(err){                    
                    return reject(err)
                }
                return resolve(result)
            })
        })
    }
}
function _getLatest(callback){
    if(_latest_timestap === ''){
        _latest_timestap = Dates.getUTCDateTime()
    }
    _PickupProgress.getFormToLatestWithPicture(_latest_timestap).then(result=>{
        //console.log(result.length)
        let _result = {
            date: Dates.getDates()
        }
        if(result.length>0){
            result.forEach(element => {
                let picture = element.beforePictures || element.afterPictures
                picture.forEach(element2 => {
                    if(element2.container === "image_checkin"){
                        //pp_image_lorry = SERVER.API.Storage.List+'/image_checkin/download/'+element2.filename
                    }
                    else if(element2.container === "image_before"){
                        if(element2.filename.includes('checkin')){
                            //pp_image_lorry = SERVER.API.Storage.List+'/image_checkin/download/'+element2.filename
                        }
                        else if(element2.filename.includes('resit')){
                            //pp_image_resit = SERVER.API.Storage.List+'/image_resit/download/'+element2.filename
                        }
                        else{
                            _latest_pick_img_before = SERVER.API.Storage.List+'/image_before/download/'+element2.filename+'?access_token='+getAuthUserAccessToken()
                        }
                    }
                    else if(element2.container === "image_after"){
                        _latest_pick_img_after = SERVER.API.Storage.List+'/image_after/download/'+element2.filename+'?access_token='+getAuthUserAccessToken()
                    }
                    else if(element2.container === "image_resit"){
                        //pp_image_resit = SERVER.API.Storage.List+'/image_resit/download/'+element2.filename
                    }
                });

                _total_complete++
                _total_not_complete--
                _total_weight += element.afterWeight
                _block_done.push(element.pointBlock)

                _latest_pick_location_block = element.pointBlock
                _latest_pick_location = element.pointBangunan
                _latest_pick_weight = element.afterWeight
                _latest_timestap = element.timestamp
            });

            _result = {
                block_done:_block_done,
                complete:_total_complete,
                pending:_total_not_complete,
                accweight:_total_weight,
                latest_pick_location_block:_latest_pick_location_block,
                latest_pick_location:_latest_pick_location,
                latest_pick_img_before:_latest_pick_img_before,
                latest_pick_img_after:_latest_pick_img_after,
                latest_pick_weight:_latest_pick_weight
            }
        }
        callback(null, _result)
    },err=>{
        callback(err, null)
    })
}

function getLatestLocation(dispatch){
    if(typeof(dispatch)!=='undefined' && dispatch!== null){
        _getLatestLocation(function(err, result){
            if(err){
                dispatch({ type: CONSTANTS.PICKUP_POINT.FAILURE, message:err })
                return
            }
            dispatch({ type: CONSTANTS.PICKUP_POINT.SUCCESS, result:result })
        })
    }        
    else{
        return new Promise(function(resolve,reject){
            _getLatestLocation(function(err, result){
                if(err){                    
                    return reject(err)
                }
                return resolve(result)
            })
        })
    }
}
function _getLatestLocation(callback){
    /*_Tracker.getList({order: 'id DESC',limit:10}).then(result=>{
        let _result = []
        let _l1 = null
        let _l2 = null
        if(result.length>0){
            let _prev = ""
            let _count = 0
            result.forEach(element => {
                if(_prev !== element.sessionId.toString()){
                    if(_count === 0){
                        _l1 = element
                        _count++
                    }
                    else if(_count === 1){
                        _l2 = element
                        _count++
                    }
                    
                    _prev = element.sessionId.toString()
                }
            });
        }

        if(_l1 !== null){
            _result.push({id:_l1.sessionId,lat:_l1.lat,lng:_l1.lng})
        }

        if(_l2 !== null){
            _result.push({id:_l2.sessionId,lat:_l2.lat,lng:_l2.lng})
        }

        callback(null,_result)
    },error=>{
        callback(error, null)
    })*/


    _Tracker.getlatest().then(result=>{
        let _result = []
        let _l1 = null
        let _l2 = null
        if(result.length>0){
            let _prev = ""
            let _count = 0
            result.forEach(element => {
                if(_prev !== element.sessionId.toString()){
                    if(_count === 0){
                        _l1 = element
                        _count++
                    }
                    else if(_count === 1){
                        _l2 = element
                        _count++
                    }
                    
                    _prev = element.sessionId.toString()
                }
            });
        }

        if(_l1 !== null){
            _result.push({id:_l1.sessionId,lat:_l1.lat,lng:_l1.lng})
        }

        if(_l2 !== null){
            _result.push({id:_l2.sessionId,lat:_l2.lat,lng:_l2.lng})
        }

        callback(null,_result)
    }, error=>{callback(error, null)})
}



async function getListDonePickToday(role, companyId, dispatch){
    try {
        let _result = []
        if(role === CONSTANTS.ROLE.ADMIN || role === CONSTANTS.ROLE.UTM){
            _result = await _PickupProgress.getListDoneToday()
        }
        else{
            _result = await _PickupProgress.getListDoneTodayByContractor(companyId)
        }

        if(Array.isArray(_result)){
            return Promise.resolve(_result)
        }

        _result.latest.image.forEach(element => {
            if(element.container === 'image_after'){
                _result.latest.img_after = SERVER.API.Storage.List+'/image_after/download/'+element.filename+'?access_token='+getAuthUserAccessToken()
            }
            else if(element.container === 'image_before'){
                _result.latest.img_before = SERVER.API.Storage.List+'/image_before/download/'+element.filename+'?access_token='+getAuthUserAccessToken()
            }
            else{
                _result.latest.img_others = SERVER.API.Storage.List+'/'+element.container+'/download/'+element.filename+'?access_token='+getAuthUserAccessToken()
            }
        })

        return Promise.resolve(_result)
    } catch (error) {
        return Promise.reject(error)
    }
}
function getLatestTracker(role, companyId, dispatch){
    if(role === CONSTANTS.ROLE.ADMIN || role === CONSTANTS.ROLE.UTM){
        return _Tracker.getLatest()
    }
    else{
        return _Tracker.getLatestByContractor(companyId)
    }
}
