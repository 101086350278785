export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'Live Tracking',
      url: '/livetracking',
      icon: 'icon-map',
    },
    {
      name: 'Job Details',
      url: '/jobdetails',
      icon: 'icon-hourglass',
    },
    {
      name: 'Location Pickup Point',
      url: '/locationpickuppoint',
      icon: 'icon-location-pin',
    },
    {
      name: 'Contractor Company',
      url: '/contractorcompany',
      icon: 'icon-location-pin',
    },
    {
      name: 'Manage Users',
      url: '/users',
      icon: 'icon-user',
    }
  ],
};
