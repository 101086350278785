import { CONSTANTS } from '../_constants';
import { SERVER } from '../_constants';
import AppUser from '../_models/appuser'

const _AppUser = new AppUser()

export const userActions = {
    registerUser,
    registerUserUtm,
    registerUserContractor,
    registerUserWorker,
    updateUser,
    findUser,
    deleteUser,
    getListUser,
    changePassword,
    resetPassword
};

// ============= state users =================
function registerUserUtm(param,dispatch){
    return registerUser(SERVER.API.AppUser.RegisterUtm, param, dispatch)
}
function registerUserContractor(param, dispatch){
    return registerUser(SERVER.API.AppUser.RegisterContractor, param, dispatch)
}
function registerUserWorker(param,dispatch){
    return registerUser(SERVER.API.AppUser.RegisterWorker, param, dispatch)
}
function registerUser(url, param, dispatch) {
    //console.log('=========user.action: registerUser()===============');
    //console.log(dispatch)
    if(typeof(dispatch)!=='undefined' && dispatch !== null){
        //console.log('=========user.action: registerUser()===0============');
        dispatch( { type: CONSTANTS.USER.REGISTER_REQUEST } );
            _AppUser.registerUser(url, param).then(result=>{
            dispatch({ type: CONSTANTS.USER.REGISTER_SUCCESS, result });
        },err=>{
            //console.log(err)
            dispatch({ type: CONSTANTS.USER.REGISTER_FAILURE, err });
        })
    }
    else{
        //console.log('=========user.action: registerUser()====1===========');
        return _AppUser.registerUser(url,param)
    }
}
function updateUser(id, param, dispatch){
    //console.log('=========user.action: updateUser()===============');
    if(typeof(dispatch)!=='undefined' && dispatch !== null){
        dispatch({ type: CONSTANTS.USER.UPDATE_REQUEST });
         _AppUser.updateUser(id, param).then(result=>{
            //console.log('=========user.action: updateUser()=====OK==========');
            dispatch({ type: CONSTANTS.USER.UPDATE_SUCCESS, result });
        },err=>{
            //console.log('=========user.action: updateUser()=====ERROR='+err+'=========');
            dispatch({ type: CONSTANTS.USER.UPDATE_FAILURE, err });
        })
    }
    else{
        return _AppUser.updateUser(id, param)
    }
}
function findUser(filter, dispatch){
    //console.log('=========user.action: findUser()===============');
    if(typeof(dispatch)!=='undefined' && dispatch !== null){
        dispatch({ type: CONSTANTS.USER.FIND_REQUEST });
        _AppUser.findUser(filter).then(result=>{
            dispatch({ type: CONSTANTS.USER.FIND_SUCCESS, result });
        },err=>{
            //console.log('=========user.action: findUser()=====ERROR='+err+'=========');
            dispatch({ type: CONSTANTS.USER.FIND_FAILURE, err });
        })
    }
    else{
        return _AppUser.findUser(filter)
    }
}
function deleteUser(id, dispatch){
    //console.log('=========user.action: deleteUser()===============');
    if(typeof(dispatch)!=='undefined' && dispatch !== null){
        dispatch({ type: CONSTANTS.USER.DELETE_REQUEST });
        _AppUser.deleteUser(id).then(result=>{
            dispatch({ type: CONSTANTS.USER.DELETE_SUCCESS, result:{id: id} });
        },err=>{
            //console.log('=========user.action: deleteUser()=====ERROR='+err+'=========');
            dispatch({ type: CONSTANTS.USER.DELETE_FAILURE, err });
        })
    }
    else{
        return _AppUser.deleteUser(id)
    }
}

async function getListUser(role,company, dispatch){
    try{
        let _u1 = []
        let _u2 = []
        let _u3 = []

        if(role === CONSTANTS.ROLE.ADMIN){
            company = 'all'
            _u1 = await _AppUser.getListUtm()
            _u2 = await _AppUser.getListContractor()
            _u3 = await _AppUser.getListWorker(company)
        }
        else if(role === CONSTANTS.ROLE.UTM){
            //_u1 = await _AppUser.getListUtm()
            _u2 = await _AppUser.getListContractor()
            company = 'all'
        }
        else if(role === CONSTANTS.ROLE.CONTRACTOR){
           _u2 = await _AppUser.getListWorker(company)
        }
        else{
            if(typeof(dispatch) !== 'undefined' && dispatch !== null){
                dispatch({type:CONSTANTS.USER.LIST_FAILURE, message:'Role Not Found.'})
            }
            else{
                return Promise.reject(null)
            }
        }
     
        // const _u3 = await _AppUser.getListWorker(company)
        //console.log(_u1)
        //console.log(_u2)
        //console.log(_u3)

        _u2.forEach(element => {
            _u1.push(element)
        });
        _u3.forEach(element => {
            _u1.push(element)
        });

        if(typeof(dispatch) !== 'undefined' && dispatch !== null){
            dispatch({type:CONSTANTS.USER.LIST_SUCCESS,result:_u1})
        }
        else{
            return Promise.resolve(_u1)
        }
    }catch(e){
        //console.log('=========user.action: getListUser()===='+e);
        if(typeof(dispatch) !== 'undefined' && dispatch !== null){
            dispatch({type:CONSTANTS.USER.LIST_FAILURE, message:e})
        }
        else{
            return Promise.reject(null)
        }
    }
}

function changePassword(oldpassword, newpassword, dispatch){
    let _param = {
        oldPassword: oldpassword,
        newPassword: newpassword
    }
    if(typeof(dispatch)!=='undefined' && dispatch !== null){
        _AppUser.changePassword(_param).then(result=>{
            dispatch({ type: CONSTANTS.USER.CHANGE_PASSWORD_SUCCESS, result});
        },err=>{
            dispatch({ type: CONSTANTS.USER.CHANGE_PASSWORD_FAILURE, err });
        })
    }
    else{
        return _AppUser.changePassword(_param)
    }
}
function resetPassword(id, newpassword, dispatch){
    let _param = {
        id: id,
        newPassword: newpassword
    }
    if(typeof(dispatch)!=='undefined' && dispatch !== null){
        _AppUser.resetPassword(_param).then(result=>{
            dispatch({ type: CONSTANTS.USER.RESET_PASSWORD_SUCCESS, result});
        },err=>{
            dispatch({ type: CONSTANTS.USER.RESET_PASSWORD_FAILURE, err });
        })
    }
    else{
        return _AppUser.resetPassword(_param)
    }
}