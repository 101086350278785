import { GetQuery, PostQuery, Post } from '../../_attfunc';
//import Dates from '../../_attfunc/dates'
import { SERVER } from '../../_constants'

class Company{
    register(param){
        return Post(SERVER.API.Company.Register, param)
    }
    update(param){
        return Post(SERVER.API.Company.Update, param)
    }
    updateById(id, param){
        return PostQuery(SERVER.API.Company.Update, param, {where:{id:id}})
    }
    softDelete(id, modifiedBy){
        // param.isDeleted = true
        //return PostQuery(SERVER.API.Company.Update+'/'+id, null, {where:{modifiedBy:modifiedBy}})
        return Post(SERVER.API.Company.Unregister+'/'+id+'/unregister', {modifiedBy: modifiedBy})
    }
    getList(param){
        return GetQuery(SERVER.API.Company.Lists, param)
    }
}

export default Company;