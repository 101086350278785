export * from './api.links';

// Component
const AUTHORIZATION = {
    LOGIN_REQUEST: 'AUTHORIZATION_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTHORIZATION_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTHORIZATION_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'AUTHORIZATION_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTHORIZATION_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTHORIZATION_LOGOUT_FAILURE',
}
const PICKUP_POINT = {
    REQUEST: 'PICKUP_POINT_REQUEST',
    SUCCESS: 'PICKUP_POINT_SUCCESS',
    FAILURE: 'PICKUP_POINT_FAILURE',

    SEARCH: 'PICKUP_POINT_SEARCH',
}
const TRACKER = {
    REQUEST: 'TRACKER_REQUEST',
    SUCCESS: 'TRACKER_SUCCESS',
    FAILURE: 'TRACKER_FAILURE',
}

// View Page
const USER = {
    SEARCH: 'USERS_SEARCH',
    LIST_REQUEST: 'USERS_LIST_REQUEST',
    LIST_SUCCESS: 'USERS_LIST_SUCCESS',
    LIST_FAILURE: 'USERS_LIST_FAILURE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    FIND_REQUEST: 'USERS_FIND_REQUEST',
    FIND_SUCCESS: 'USERS_FIND_SUCCESS',
    FIND_FAILURE: 'USERS_FIND_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE'
}
const DASHBOARD = {
    TICK1: 1,
    TICK2: 2,
    TICK3: 3,
    START_YEAR: 2019,
    VIEW: 'DASHBOARD_VIEW',
    REQUEST: 'DASHBOARD_REQUEST',
    SUCCESS: 'DASHBOARD_SUCCESS',
    FAILURE: 'DASHBOARD_FAILURE',
}
const LIVETRACKING = {
    REQUEST: 'LIVETRACKING_REQUEST',
    SUCCESS: 'LIVETRACKING_SUCCESS',
    FAILURE: 'LIVETRACKING_FAILURE',
}
const JOBHISTORY = {
    REQUEST: 'JOBHISTORY_REQUEST',
    SUCCESS: 'JOBHISTORY_SUCCESS',
    FAILURE: 'JOBHISTORY_FAILURE',
}
const COMPANY = {
    LIST_REQUEST: 'COMPANY_LIST_REQUEST',
    LIST_SUCCESS: 'COMPANY_LIST_SUCCESS',
    LIST_FAILURE: 'COMPANY_LIST_FAILURE',

    UPDATE_REQUEST: 'COMPANY_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'COMPANY_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'COMPANY_UPDATE_FAILURE',

    DELETE_REQUEST: 'COMPANY_DELETE_REQUEST',
    DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
    DELETE_FAILURE: 'COMPANY_DELETE_FAILURE',

    FIND_REQUEST: 'COMPANY_FIND_REQUEST',
    FIND_SUCCESS: 'COMPANY_FIND_SUCCESS',
    FIND_FAILURE: 'COMPANY_FIND_FAILURE',

    REGISTER_REQUEST: 'COMPANY_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'COMPANY_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'COMPANY_REGISTER_FAILURE',
}


// Others
const ROLE = {
    ADMIN: 'ADMIN',
    UTM: 'UTM',
    CONTRACTOR: 'CONTRACTOR',
    WORKER: 'WORKER'
}

const PATH = {
    MAIN: '/',
    LOGIN: '/login',
    NOT_FOUND: '/404'

}

export const CONSTANTS = {
    CLEAR: 'CLEAR',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',

    AUTHORIZATION,
    PICKUP_POINT,
    USER,
    DASHBOARD,
    LIVETRACKING,
    JOBHISTORY,
    COMPANY,
    ROLE,
    TRACKER,
    PATH,


    DATE_FORMAT: {
        DATE_TIME:'dd-mm-yyyy HH:MM',
        DATE: 'dd-mm-yyyy',
        TIME: 'HH:MM'
    },


    GOGGLE_API_KEY: "AIzaSyCttfQYamUIyUh1abii4WgndgkFdcQWMeA" 


}