import { combineReducers } from 'redux';

import { authorization } from './r_authorization'
import { pickuppoint } from './r_pickuppoint'
import { dashboard } from './v_dashboard'
import { vhistory } from './v_history'
import { liveTracking } from './v_livetracking'
import { users } from './v_users'
import { company } from './r_company'

const rootReducer = combineReducers({
  authorization,
  pickuppoint,
  dashboard,
  vhistory,
  liveTracking,
  users,
  company
});

export default rootReducer;