import { CONSTANTS } from '../_constants';
let initState = {data:[]}

export function pickuppoint(state = initState, action) {
  switch (action.type) {
    case CONSTANTS.PICKUP_POINT.REQUEST:
      return {
        request: true
      };
    case CONSTANTS.PICKUP_POINT.SUCCESS:
      // return {
      //   total: action.result.length,
      //   data: action.result
      // };
      let _number = 1
        //console.log(action.result)
        for (let index = 0; index < action.result.length; index++) {
            action.result[index].number = _number++
        }
        return {
            data:action.result
        };
    case CONSTANTS.PICKUP_POINT.FAILURE:
      return {
        message: action.message
      };
    case CONSTANTS.PICKUP_POINT.SEARCH:
      return  {
          search: true,
          sdata: action.data,
          data: [...state.data]
      }
    case CONSTANTS.CLEAR:
        return initState
    default:
      return state
  }
}