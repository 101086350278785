import { CONSTANTS } from '../_constants';
let initState = {data:[], tracker:{}, blockdone:{}, latest:{}}

export function liveTracking(state = initState, action) {
  switch (action.type) {
    case CONSTANTS.LIVETRACKING.REQUEST:
      if(typeof(state.tracker)==='undefined'){
        state.tracker = {}
      }
      return {
        request:true,
        latest: state.latest,
        tracker:state.tracker,
        blockdone:state.blockdone
      };
    case CONSTANTS.LIVETRACKING.SUCCESS:
      if(typeof(state.tracker)==='undefined'){
        state.tracker = {}
      }
      if(Object.keys(action.result).length === 0 && action.result.constructor === Object){
        action.result = state.data
      }
      //console.log(state.data)
      //console.log(action.result)
      return {
        blockdone: action.result.blockdone,
        latest: action.result.latest,
        tracker:state.tracker
      }
    case CONSTANTS.LIVETRACKING.FAILURE:
      return {
        data:[...state.data],
        message: action.message
      };
    case CONSTANTS.TRACKER.SUCCESS:
      return {
        tracker: action.result,
        blockdone:state.blockdone,
        latest: state.latest
      }
    case CONSTANTS.CLEAR:
        return initState
    default:
      state.data = []
      return state
  }
}