import { Get,GetQuery } from '../../_attfunc';
import Dates from '../../_attfunc/dates'
import { SERVER } from '../../_constants'

class PickupProgress {
    getList(filters){
        return GetQuery(SERVER.API.PickupProgress.Lists,filters)
    }
    getTodayPickupProgress(){
        let d_ = Dates.getUTCDateTimeTZ(Dates.getDates()+' 00:00:00')
        return GetQuery(SERVER.API.PickupProgress.Lists,{where:{timestamp:{'gt':d_}}})
    }
    getFromTo(frm, tto){
        let d_frm = Dates.getUTCDateTimeTZ(frm+' 00:00:00')
        let d_tto = Dates.getUTCDateTimeTZ(tto+' 23:59:59')
        // {"where":{"timestamp":{"between":["2019-02-10T00:00:00Z","2019-02-11T23:00:00Z"] } }}
        return GetQuery(SERVER.API.PickupProgress.Lists,{where:{timestamp:{between: [d_frm,d_tto]}}})
    }
    getFromToWithPicture(frm, tto){
        let d_frm = Dates.getUTCDateTimeTZ(frm+' 00:00:00')
        let d_tto = Dates.getUTCDateTimeTZ(tto+' 23:59:59')
        // {"include":["beforePictures","afterPictures"],"where":{"timestamp":{"between":["2019-02-10T00:00:00Z","2019-02-10T23:00:00Z"] } }}
        //return GetQuery(SERVER.API.PickupProgress.Lists,{include:["beforePictures","afterPictures"],where:{timestamp:{between: [d_frm,d_tto]}}})
        return GetQuery(SERVER.API.PickupProgress.Lists,{include:["beforePictures"],where:{timestamp:{between: [d_frm,d_tto]}}})
    }
    getFormToLatestWithPicture(datetime){
        let d_frm = Dates.getUTCDateTimeTZ(datetime)
        // {"include":["beforePictures","afterPictures"],"where":{"timestamp":{"between":["2019-02-10T00:00:00Z","2019-02-10T23:00:00Z"] } }}
        //return GetQuery(SERVER.API.PickupProgress.Lists,{include:["beforePictures","afterPictures"],where:{timestamp:{between: [d_frm,d_tto]}}})
        return GetQuery(SERVER.API.PickupProgress.Lists,{include:["beforePictures"],where:{timestamp:{gt: d_frm}}})
    }
    getPickupProgressPicture(id,type,filters){
        let types = ''
        if(type === 'before'){
            types = 'beforePictures'
        }
        else if(type === 'after'){
            types = 'afterPictures'
        }
        //http://localhost:3005/api/PickupProgresses/19/beforePictures
        return Get(SERVER.API.PickupProgress.Lists+'/'+id+'/'+types,filters)
    }


    getListDoneToday(){
        return GetQuery(SERVER.API.PickupProgress.DoneToday)
    }
    getListDoneTodayByContractor(id){
        return GetQuery(SERVER.API.PickupProgress.Lists+'/'+id+'/todaydonebycontractor')
    }
}

export default PickupProgress;
/*function getPickupProgressPicture(){
    //pickpoint_Sebelum_20190212_012223_1445086417
    //pickpoint_Resit_20190212_012417_921136196.jpeg
    //http://localhost:3005/api/Storages/image_before/files/pickpoint_Sebelum_20190212_012223_1445086417.jpeg    
}*/