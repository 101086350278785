import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
import navigation_admin from '../../_nav_admin';
import navigation_utm from '../../_nav_utm';
import navigation_contractor from '../../_nav_contractor';
// routes config
import routes from '../../routes';

import { AuthorizationActions } from '../../_actions'
import { CONSTANTS } from '../../_constants';

const DefaultAside = React.lazy(() => import('./DefaultAside'));
const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

class DefaultLayout extends Component {

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  signOut(e) {
    e.preventDefault()
    // console.log(this.props)
    AuthorizationActions.logout(this.props.history, this.props.dispatch)
  }

  render() {
    //console.log('=============DefaultLayout==============')
    const { user } = this.props.authorization
    let _navigation = { items:[] }
    if(typeof(user)!=='undefined'){
      if(user.role === CONSTANTS.ROLE.ADMIN){
        _navigation = navigation_admin
      }else if(user.role === CONSTANTS.ROLE.UTM){
        _navigation = navigation_utm
      }else if(user.role === CONSTANTS.ROLE.CONTRACTOR){
        _navigation = navigation_contractor
      }else{ //user.role === CONSTANTS.ROLE.WORKER
        _navigation = navigation
      }
    }

    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={e=>this.signOut(e)}/>
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
            <AppSidebarNav navConfig={_navigation} {...this.props} />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes}/>
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    //console.log(route.component)
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <route.component {...props} />
                        )} />
                    ) : (null);
                  })}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </Suspense>
            </Container>
          </main>
          <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

//export default DefaultLayout;

function mapStateToProps(state) {
  return state
}
const DefaultLayouts = connect(mapStateToProps)(DefaultLayout);
export default DefaultLayouts;
