import React from 'react';
import DefaultLayout from './containers/DefaultLayout';

const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const Users = React.lazy(() => import('./views/ManageUser/Users'));
const LiveTracking = React.lazy(() => import('./views/LiveTracking/Live'))
const History = React.lazy(() => import('./views/ViewHistory/History'));
const LocationPickupPoint = React.lazy(() => import('./views/LocationPickupPoint/LocationPickupPoint'));
const Profile = React.lazy(() => import('./views/ManageUser/Profile'));
const ContractorCompany = React.lazy(() => import('./views/ContractorCompany/Company'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/livetracking', exact: true,  name: 'LiveTracking', component: LiveTracking },
  { path: '/jobdetails', exact: true,  name: 'JobDetails', component: History },
  { path: '/users', exact: true,  name: 'Users', component: Users },
  { path: '/locationpickuppoint', exact: true,  name: 'Location PickupPoint', component: LocationPickupPoint },
  { path: '/profile', exact: true,  name: 'Profile', component: Profile },
  { path: '/contractorcompany', exact: true,  name: 'Contractor Company', component: ContractorCompany },
  
];

export default routes;
