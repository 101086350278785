//import { SERVER } from '../_constants';
//import { Post,PostQuery,Patch,Get,GetQuery } from '../_attfunc';
import Dates from '../_attfunc/dates';
import Session from '../_models/session'
import PickupProgress from '../_models/pickupprogress'
import PickupPoint from '../_models/pickuppoint'
import { CONSTANTS } from '../_constants'

const _Session = new Session()
const _PickupProgress = new PickupProgress()
const _PickupPoint = new PickupPoint()


export const DashboardActions = {
    getStatistic,
    getData,
    getSessionDaily,
    getListPickupProgressDaily
};

async function getStatistic(tick, pickuppoint, dispatch){
    try {
        // 1. get list of pickup point
        let pp_data = []
        let _total_pp = 1
        if(typeof (pickuppoint.total)==='undefined'){
            pp_data = await _PickupPoint.getPickupPoint()
            _total_pp = pp_data.length
        }
        else{
            _total_pp = pickuppoint.total
        }

        let _session_id = []

        let _total_tonne = 0.0
        let _total_finish_session = 0
        let _total_time_job_done = 0
        let _average_weight = 0.0

        //let days = 0
        let hours = 0
        let minutes = 0
        let seconds = 0

        let _tempData = {}
        let _temp_performance = {}

        let _chart_tonnes_height = 10
        let _chart_tonnes_label = []
        let _temp_chart_tonnes_label = []
        let _chart_tonnes_data = []
        let _temp_chart_tonnes_data = []

        let _performance = 0
        let _chart_performance_label = []
        let _temp_chart_performance_label = []
        let _chart_performance_data = []
        let _temp_chart_performance_data = []

        let _labelName = ""

        // 2. get time start-finish-weight from session
        // 2. get list of progress for this month
        //console.log(props)
        if(tick === CONSTANTS.DASHBOARD.TICK1){
            let _totalDay = Dates.getTotalDayOfMonts()
            

            for (let index = 1; index <= _totalDay; index++) {
                if(index.toString().length === 1){
                    _tempData['0'+index.toString()] = 0
                    _temp_performance['0'+index.toString()] = 0
                    _chart_tonnes_label.push('0'+index.toString())
                    _chart_performance_label.push('0'+index.toString())
                }
                else{
                    _tempData[index.toString()] = 0
                    _chart_tonnes_label.push(index.toString())
                    _temp_performance[index.toString()] = 0
                    _chart_performance_label.push(index.toString())
                }
            }
            // console.log(_chart_tonnes_label)

            // Day
            // 1. admin login
            let data2 = await getSessionDaily()

            let _lblPrev = ''
            //let _weightSameDate = -1
            data2.forEach(element => {
                if(element.startTime!=null && element.stopTime!=null){
                    _session_id.push({sessionId:element.id})
                    _total_time_job_done += Math.abs(new Date(element.stopTime) - new Date(element.startTime)) / 1000;
                    _total_finish_session++
                    if(element.totalWeight==null){
                        element.totalWeight = 0.0
                    }
                    _total_tonne += element.totalWeight

                    let _lbl = Dates.getDateNumber(element.startTime)
                    if(_lblPrev === _lbl){
                        _temp_chart_tonnes_data[_temp_chart_tonnes_data.length-1] += element.totalWeight
                    }
                    else{
                        _temp_chart_tonnes_data.push(element.totalWeight)
                        _temp_chart_tonnes_label.push(_lbl)
                        _lblPrev = _lbl
                    }
                    
                    if(_chart_tonnes_height < _temp_chart_tonnes_data[_temp_chart_tonnes_data.length-1]){
                        _chart_tonnes_height = _temp_chart_tonnes_data[_temp_chart_tonnes_data.length-1]
                    }
                }
            })

            for (let index = 0; index < _temp_chart_tonnes_label.length; index++) {
                _tempData[_temp_chart_tonnes_label[index]] = _temp_chart_tonnes_data[index]
            }
            for (let index = 0; index < _chart_tonnes_label.length; index++) {
                _chart_tonnes_data.push(_tempData[_chart_tonnes_label[index]])     
            }


            // console.log(_chart_tonnes_label)
            // console.log(_chart_tonnes_data)


            // get total days between two dates
            //days = Math.floor(_total_time_job_done / 86400);       
            hours = Math.floor(_total_time_job_done / 3600) % 24;
            minutes = Math.floor(_total_time_job_done / 60) % 60;
            seconds = _total_time_job_done % 60;

            //console.log('Total Tonnes: '+total_tonne/1000)
            //console.log(`Average Time: ${hours}:${minutes}:${seconds}`)
            //console.log('Average Weight: '+total_tonne/total_finish_session)
            if(_total_finish_session>0){
                _average_weight = Math.floor(_total_tonne/_total_finish_session*1000)/1000
            }
            
            let data3 = []
            if(_session_id.length > 0){
                data3 = await getListPickupProgressBySession(_session_id)
            }
            
            //chart_performance_labelName = 'Daily Performance'
            //Dates.getMonthName() + ', '+ Dates.getYear()
            data3.forEach(element => {
                let _lbl = Dates.getDateNumber(element.timestamp)
                if(_lblPrev === _lbl){
                    _temp_chart_performance_data[_temp_chart_performance_data.length-1] += 1
                }
                else{
                    _temp_chart_performance_data.push(1)
                    // _chart_performance_label.push(_lbl)
                    _temp_chart_performance_label.push(_lbl)
                    _lblPrev = _lbl
                }
            });

            // console.log(_temp_chart_performance_data)
            // console.log(_temp_chart_performance_label)

            for (let index = 0; index < _temp_chart_performance_data.length; index++) {
                _temp_chart_performance_data[index] = Math.floor(_temp_chart_performance_data[index]*100*1000/_total_pp)/1000
                _performance += _temp_chart_performance_data[index]
            }
            if(_temp_chart_performance_data.length > 0){
                _performance = Math.floor(_performance/_temp_chart_performance_data.length*100)/100
            }
            _labelName = Dates.getMonthName() + ', '+ Dates.getYear()

            
            for (let index = 0; index < _temp_chart_performance_label.length; index++) {
                _temp_performance[_temp_chart_performance_label[index]] = _temp_chart_performance_data[index]
                // console.log(_temp_chart_performance_data[index])
            }
            // console.log(_chart_performance_label)
            for (let index = 0; index < _chart_performance_label.length; index++) {
                _chart_performance_data.push(_temp_performance[_chart_performance_label[index]])
                // console.log(_temp_performance[_chart_performance_label[index]])
            }

            // console.log(_chart_performance_data)


        }
        else if(tick === CONSTANTS.DASHBOARD.TICK2){
            _tempData = {'01':0,'02':0,'03':0,'04':0,'05':0,'06':0,'07':0,'08':0,'09':0,'10':0,'11':0,'12':0}
            _temp_performance = {'01':0,'02':0,'03':0,'04':0,'05':0,'06':0,'07':0,'08':0,'09':0,'10':0,'11':0,'12':0}
            _chart_tonnes_label = ['01','02','03','04','05','06','07','08','09','10','11','12']
            _chart_performance_label = ['01','02','03','04','05','06','07','08','09','10','11','12']
            //_chart_tonnes_labelName = ''
            // 1. admin login
            let data2 = await getSessionMonthly()

            let _lblPrev = ''
            //let _weightSameDate = -1
            data2.forEach(element => {
                if(element.startTime!=null && element.stopTime!=null){
                    _session_id.push({sessionId:element.id})
                    _total_time_job_done += Math.abs(new Date(element.stopTime) - new Date(element.startTime)) / 1000;
                    _total_finish_session++
                    if(element.totalWeight==null){
                        element.totalWeight = 0.0
                    }
                    _total_tonne += element.totalWeight

                    let _lbl = Dates.getMonthNumber(element.startTime)
                    if(_lblPrev === _lbl){
                        _temp_chart_tonnes_data[_temp_chart_tonnes_data.length-1] += element.totalWeight
                    }
                    else{
                        _temp_chart_tonnes_data.push(element.totalWeight)
                        _temp_chart_tonnes_label.push(_lbl)
                        _lblPrev = _lbl
                    }

                    if(_chart_tonnes_height < _temp_chart_tonnes_data[_temp_chart_tonnes_data.length-1]){
                        _chart_tonnes_height = _temp_chart_tonnes_data[_temp_chart_tonnes_data.length-1]
                    }
                }
            })

            // get total days between two dates
            //days = Math.floor(_total_time_job_done / 86400);       
            hours = Math.floor(_total_time_job_done / 3600) % 24;
            minutes = Math.floor(_total_time_job_done / 60) % 60;
            seconds = _total_time_job_done % 60;

            //console.log('Total Tonnes: '+total_tonne/1000)
            //console.log(`Average Time: ${hours}:${minutes}:${seconds}`)
            //console.log('Average Weight: '+total_tonne/total_finish_session)
            if(_total_finish_session>0){
                _average_weight = Math.floor(_total_tonne/_total_finish_session*1000)/1000
            }
            
            let data3 = []
            if(_session_id.length > 0){
                data3 = await getListPickupProgressBySession(_session_id)
            }
            
            //chart_performance_labelName = 'Daily Performance'
            //Dates.getMonthName() + ', '+ Dates.getYear()
            let _tdays = []
            let _dayPrev = ''
            data3.forEach(element => {
                let _lbl = Dates.getMonthNumber(element.timestamp)
                let _dayN = Dates.getDateNumber(element.timestamp)
                //console.log(_dayN)
                if(_lblPrev === _lbl){
                    _temp_chart_performance_data[_temp_chart_performance_data.length-1] += 1
                    //console.log(_dayN + ' , ' +_dayPrev)
                    if(_dayPrev !== _dayN){
                        _tdays[_tdays.length-1]+=1
                        _dayPrev = _dayN
                    }
                }
                else{
                    _temp_chart_performance_data.push(1)
                    _temp_chart_performance_label.push(_lbl)
                    _lblPrev = _lbl
                    _dayPrev = _dayN
                    _tdays.push(1)
                }
            });
            //console.log(_tdays)

            for (let index = 0; index < _temp_chart_performance_data.length; index++) {
                _temp_chart_performance_data[index] = Math.floor(_temp_chart_performance_data[index]*100*1000/_total_pp/_tdays[index])/1000
                _performance += _temp_chart_performance_data[index]
            }
            _performance = Math.floor(_performance/_temp_chart_performance_data.length*100)/100
            _labelName = Dates.getYear()

            for (let index = 0; index < _temp_chart_tonnes_label.length; index++) {
                _tempData[_temp_chart_tonnes_label[index]] = _temp_chart_tonnes_data[index]
                _temp_performance[_temp_chart_tonnes_label[index]] = _temp_chart_performance_data[index]
            }
        
            for (let index = 0; index < _chart_tonnes_label.length; index++) {
                // console.log(index)
                _chart_tonnes_data.push(_tempData[_chart_tonnes_label[index]])
                _chart_performance_data.push(_temp_performance[_chart_performance_label[index]])
            }

            // console.log(_chart_tonnes_data)
            // console.log(_chart_performance_data)

        }
        else if(tick === CONSTANTS.DASHBOARD.TICK3){

        }

        let _chart_tonnes = {
            labelName:_labelName,
            label:_chart_tonnes_label,
            data:_chart_tonnes_data,
            height:_chart_tonnes_height
        }
        let _chart_performance = {
            labelName:_labelName,
            label:_chart_performance_label,
            data:_chart_performance_data,
        }
        let _result = {
            total_tonne:Math.floor(_total_tonne*100)/100,
            performance: _performance,
            average_time:`${hours}:${minutes}:${seconds}`,
            average_weight:_average_weight,
            chart_tonnes:_chart_tonnes,
            chart_performance:_chart_performance
        }

        if(typeof(dispatch)!=='undefined' && dispatch!==null){
            if(typeof (pickuppoint.total)==='undefined'){
                dispatch({ type: CONSTANTS.PICKUP_POINT.SUCCESS, result:pp_data })
            }
            dispatch({ type:CONSTANTS.DASHBOARD.SUCCESS, result:{tick:tick,data:_result} })
        }
        else{
            return Promise.resolve(_result)
        }        
    } 
    catch (error) {
        //console.log('=============Dashboard: getStatistic()========'+error+'===============================')
        if(typeof(dispatch)!=='undefined' && dispatch!==null){
            dispatch({type:CONSTANTS.DASHBOARD.FAILURE, message:error})
        }
        else{
            return Promise.reject(error)
        }
    }
}

function getData(){
    let _year = '2019'
    let _month = Dates.getUTCMonthNumber()
    let _date = '01'
    let _times = '00:00:00'
    let _d_start = Dates.getUTCDateTimeTZ(_year+'-'+_month+'-'+_date+' '+_times)
    return _Session.getList({where:{'startTime':{'gt':_d_start}}})
}

function getSessionDaily(){
    let _d_from = Dates.getYear()+'-'+Dates.getMonthNumber()+'-01'
    let _d_to = Dates.getYear()+'-'+Dates.getMonthNumber()+'-'+Dates.getDateNumber()
    return _Session.getFromTo(_d_from,_d_to)
}
function getSessionMonthly(){
    let _d_from = Dates.getYear()+'-01-01'
    let _d_to = Dates.getYear()+'-'+Dates.getMonthNumber()+'-'+Dates.getDateNumber()
    return _Session.getFromTo(_d_from,_d_to)
}
// function getSessionYearly(){
//     let _d_from = CONSTANTS.DASHBOARD.START_YEAR.toString()+'-01-01'
//     let _d_to = Dates.getYear()+'-'+Dates.getMonthNumber()+'-'+Dates.getDateNumber()
//     return _Session.getFromTo(_d_from,_d_to)
// }

function getListPickupProgressDaily(){
    let _d_from = Dates.getYear()+'-'+Dates.getMonthNumber()+'-01'
    let _d_to = Dates.getYear()+'-'+Dates.getMonthNumber()+'-'+Dates.getDateNumber()

    return _PickupProgress.getFromTo(_d_from,_d_to)
}
// function getListPickupProgressMonthly(){

// }
// function getListPickupProgressYearly(){

// }

function getListPickupProgressBySession(arrSId){
    //console.log(arrSId)
    return _PickupProgress.getList({where:{and:[{or:arrSId},{status:'pick'}]}})
}