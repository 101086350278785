import { CONSTANTS } from '../_constants';
let initState = {data:[]}

export function company(state = initState, action) {
  switch (action.type) {
    case CONSTANTS.COMPANY.REGISTER_REQUEST:
        return {
            data:[action.result,...state.data]
        };
    case CONSTANTS.COMPANY.REGISTER_SUCCESS:
        if(state.data.length>0){
            action.result.number = state.data[state.data.length-1].number++
        }
        else{
            action.result.number = 1
            //state.data.push({action.result})
        }

        return {
            data:[action.result,...state.data]
        };
    case CONSTANTS.COMPANY.REGISTER_FAILURE:
        return {
            message:action.message,
            data:[...state.data]
        };
    case CONSTANTS.COMPANY.LIST_SUCCESS:
      let _number = 1
      for (let index = 0; index < action.result.length; index++) {
          action.result[index].number = _number++
      }
      return {
          data:action.result
      };
    case CONSTANTS.COMPANY.LIST_FAILURE:
        return {
            message:action.message,
            data:[...state.data]
        };
    case CONSTANTS.COMPANY.SEARCH:
        return  {
          search: true,
          sdata: action.data,
          data: [...state.data]
        }
    case CONSTANTS.COMPANY.UPDATE_SUCCESS:
        let _b = state.data
        //console.log(action)
        for (let index = 0; index < _b.length; index++) {
            const element = _b[index];
            if(element.id === parseInt(action.param.id)){
                _b[index].nfc_id = action.param.nfc_id
                _b[index].rfid_id = action.param.rfid_id
                break
            }
        }
        return {
            data: _b
        }
    case CONSTANTS.COMPANY.UPDATE_FAILURE:
        return  {
            message:action.message,
            data:[...state.data]
        }
    case CONSTANTS.COMPANY.DELETE_SUCCESS:
        let _a = state.data
        for (let index = 0; index < _a.length; index++) {
            const element = _a[index];
            if(element.id === parseInt(action.id)){
                _a.splice(index,1)
                break
            }
        }
        return {
            data: _a
        };
    case CONSTANTS.COMPANY.DELETE_FAILURE:
        return  {
            message:action.message,
            data:[...state.data]
        }
    case CONSTANTS.CLEAR:
        return initState
    default:
        return state
  }
}