//const MAIN = 'http://localhost:3005'
// const MAIN = 'http://warms.utm.my'
var MAIN = ''
var URL = window.location.href
var HOSTNAME = window.location.hostname
if(URL.includes(':6005')){
    MAIN = 'http://'+ HOSTNAME + ':3005'
}

export const SERVER = {
    API:{
        AppUser:{
            Users: MAIN+'/api/AppUsers',
            Login: MAIN+'/api/AppUsers/login',
            Logout: MAIN+'/api/AppUsers/logout',
            RegisterContractor: MAIN+'/api/AppUsers/register/contractor',
            RegisterUtm: MAIN+'/api/AppUsers/register/utm',
            RegisterWorker: MAIN+'/api/AppUsers/register/worker',
            ListUtm: MAIN+'/api/AppUsers/utms',
            ListContractor: MAIN+'/api/AppUsers/contractors',
            ListWorker: MAIN+'/api/AppUsers/workers',
            ChangePassword: MAIN+'/api/AppUsers/change-password',
            ResetPassword: MAIN+'/api/AppUsers/resetpasswordbyadmin',
            Updates: MAIN+'/api/AppUsers/updateuser'
        },
        Company:{
            Lists: MAIN+'/api/Companies',
            Register: MAIN+'/api/Companies',
            Update: MAIN+'/api/Companies/update',
            Unregister: MAIN+'/api/Companies/'
        },
        PickupPoint:{
            Lists: MAIN+'/api/PickupPoints',
            Count: MAIN+'/api/PickupPoints/count',
            Update: MAIN+'/api/PickupPoints/update'
        },
        PickupProgress:{
            Lists: MAIN+'/api/PickupProgresses',
            DoneToday: MAIN+'/api/PickupProgresses/todaydone'
        },
        Session:{
            Lists: MAIN+'/api/Sessions'
        },
        Storage:{
            List: MAIN+'/api/Storages'
        },
        Tracker:{
            Lists: MAIN+'/api/Trackers',
            Latest: MAIN+'/api/Trackers/latest'
        },
        PinCode: {
            MAIN: MAIN+'/api/PinCodes/findOne',
        }
    }
}