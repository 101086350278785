export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'Live Tracking',
      url: '/livetracking',
      icon: 'icon-map',
    },
    {
      name: 'Job Details',
      url: '/jobdetails',
      icon: 'icon-hourglass',
    },
    {
      name: 'Manage Users',
      url: '/users',
      icon: 'icon-user',
    }
  ],
};
