import { SERVER } from '../_constants';
import Dates from '../_attfunc/dates'
import { CONSTANTS } from '../_constants'
import PickupProgress from '../_models/pickupprogress'
import Session from '../_models/session'
import { getAuthUserAccessToken } from '../_attfunc/auth'

const _PickupProgress = new PickupProgress()
const _Session = new Session()

export const VHistoryActions = {
    getListPickupProgressWithPicture,
    getLatestPickupProgressWithPicture
};

/*function getListPickupProgressWithPicture(dates_frm, dates_to, dispatch){
    _getListPickupProgressWithPicture(dates_frm, dates_to, function(err, result){
        if(typeof(dispatch)!=='undefined' && dispatch!== null){
            if(err){
                dispatch({type: CONSTANTS.JOBHISTORY.FAILURE, message: err})
            }
            else{
                dispatch({type: CONSTANTS.JOBHISTORY.SUCCESS, result: result})
            }
        }
        else{
            return new Promise(function(resolve, reject) {
                if(err){
                    return reject(err)
                }

                return resolve(result)
            })
        }
    })
    
}*/
function getListPickupProgressWithPicture(dates_frm, dates_to, dispatch){
    _getListPickupProgressWithPicture(dates_frm, dates_to, function(err, result){
        if(typeof(dispatch)!=='undefined' && dispatch!== null){
            if(err){
                dispatch({type: CONSTANTS.JOBHISTORY.FAILURE, message: err})
            }
            else{
                dispatch({type: CONSTANTS.JOBHISTORY.SUCCESS, result: result})
            }
        }
        else{
            return new Promise(function(resolve, reject) {
                if(err){
                    return reject(err)
                }

                return resolve(result)
            })
        }
    })
    
}
function _getListPickupProgressWithPicture(dates_frm, dates_to, callback){
    //let allresult = []
    _PickupProgress.getFromToWithPicture(dates_frm, dates_to).then(result=>{
        /*result.forEach(element => {
                let picture = element.beforePictures || element.afterPictures
    
                let pp_image_lorry = null
                let pp_image_before = null
                let pp_image_after = null
                let pp_image_resit = null

                picture.forEach(element2 => {
                    if(element2.container === "image_checkin"){
                        pp_image_lorry = SERVER.API.Storage.List+'/image_checkin/download/'+element2.filename
                    }
                    else if(element2.container === "image_before"){
                        if(element2.filename.includes('checkin')){
                            pp_image_lorry = SERVER.API.Storage.List+'/image_checkin/download/'+element2.filename
                        }
                        else if(element2.filename.includes('Resit')){
                            pp_image_resit = SERVER.API.Storage.List+'/image_resit/download/'+element2.filename
                        }
                        else{
                            pp_image_before = SERVER.API.Storage.List+'/image_before/download/'+element2.filename
                        }
                    }
                    else if(element2.container === "image_after"){
                        pp_image_after = SERVER.API.Storage.List+'/image_after/download/'+element2.filename
                    }
                    else if(element2.container === "image_resit"){
                        pp_image_resit = SERVER.API.Storage.List+'/image_resit/download/'+element2.filename
                    }
                });

                //let dd = new Date(element.timestamp)
                //console.log(dd)
                //console.log(element.timestamp)
                //console.log(Dates.getDates(element.timestamp))
                //console.log(Dates.getTime(element.timestamp))
    
                let _diff = Dates.getDiffTime(element.timestamp,new Date())
                let _timepass = ''
                if(_diff.day>0){
                    _timepass = _diff.day + " Days Ago"
                }
                else if(_diff.hour>0){
                    _timepass = _diff.hour + " Hours Ago"
                }
                else if(_diff.minute>0){
                    _timepass = _diff.minute + " Minutes Ago"
                }
                else if(_diff.second>0){
                    _timepass = Math.round(_diff.second) + " Seconds Ago"
                }
                let _timedate = Dates.getTime(element.timestamp) + ' | ' + Dates.getMonthName(element.timestamp) + ' ' + Dates.getDateNumber(element.timestamp)+', '+Dates.getYear(element.timestamp)
                //10 Hours Ago
                //15:13:37 | Jan 1, 2015


                allresult.push({
                    block:element.pointBlock,
                    bangunan:element.pointBangunan,
                    weight:element.afterWeight,
                    datetime:element.timestamp,

                    timepass:_timepass,
                    timedate:_timedate,

                    image_lorry:pp_image_lorry,
                    image_before:pp_image_before,
                    image_after:pp_image_after,
                    image_resit:pp_image_resit
                })
        });
    
        callback(null, allresult)*/

        let _sessionId = []
            let _prevSession = ''
            result.forEach(element => {
                if(_prevSession !== element.sessionId){
                    _prevSession = element.sessionId
                    _sessionId.push({id:_prevSession})
                }
            });
    
            _Session.getList({include:["pictures"],where:{or:_sessionId}}).then(result2=>{
                //console.log(result2)
                result2.forEach(element => {
                    result.push(element)
                });
                // console.log(result)
                
                callback(null, _process(result))
            },error=>{callback(error, null)})



    },err => {
        callback(err,null)
    })
}

function getLatestPickupProgressWithPicture(total, dispatch){
    _getLatestPickupProgressWithPicture(total, function(err, result){
        if(typeof(dispatch)!=='undefined' && dispatch!==null){
            if(err){
                dispatch({type: CONSTANTS.JOBHISTORY.FAILURE, message: err})
            }
            else{
                dispatch({type: CONSTANTS.JOBHISTORY.SUCCESS, result: result})
            }            
        }
        else{
            return new Promise(function(resolve,reject){
                if(err){
                    return reject(err)
                }
                return resolve(result)
            })
        }
    })
}
function _getLatestPickupProgressWithPicture(total, callback){
    if(typeof(total)==='undefined'){
        total = 5
    }
    if(total===null){
        total = 5
    }

    _PickupProgress.getList({include:["beforePictures"],order: 'id DESC',limit:total}).then(result=>{
        if(result.length<total){
            let _sessionId = []
            let _prevSession = ''
            result.forEach(element => {
                if(_prevSession !== element.sessionId){
                    _prevSession = element.sessionId
                    _sessionId.push({id:_prevSession})
                }
            });
    
            _Session.getList({include:["pictures"],where:{or:_sessionId}}).then(result2=>{
                //console.log(result2)
                result2.forEach(element => {
                    result.push(element)
                });
                
                callback(null, _process(result))
            },error=>{callback(error, null)})
        }
        else{
            callback(null, _process(result))
        }
    },err=>{
        callback(err, null)
    })
}
function _process(listdata){
    //console.log(listdata)
    let allresult = []
    listdata.forEach(element => {
        let picture = element.pictures || element.beforePictures || element.afterPictures

        let pp_image_lorry = null
        let pp_image_before = null
        let pp_image_after = null
        let pp_image_resit = null

        picture.forEach(element2 => {
            if(element2.container === "image_checkin"){
                pp_image_lorry = SERVER.API.Storage.List+'/image_checkin/download/'+element2.filename+'?access_token='+getAuthUserAccessToken()
            }
            else if(element2.container === "image_before"){
                if(element2.filename.includes('checkin')){
                    pp_image_lorry = SERVER.API.Storage.List+'/image_checkin/download/'+element2.filename+'?access_token='+getAuthUserAccessToken()
                }
                else if(element2.filename.includes('Resit')){
                    pp_image_resit = SERVER.API.Storage.List+'/image_resit/download/'+element2.filename+'?access_token='+getAuthUserAccessToken()
                }
                else{
                    pp_image_before = SERVER.API.Storage.List+'/image_before/download/'+element2.filename+'?access_token='+getAuthUserAccessToken()
                }
            }
            else if(element2.container === "image_after"){
                pp_image_after = SERVER.API.Storage.List+'/image_after/download/'+element2.filename+'?access_token='+getAuthUserAccessToken()
            }
            else if(element2.container === "image_resit"){
                pp_image_resit = SERVER.API.Storage.List+'/image_resit/download/'+element2.filename+'?access_token='+getAuthUserAccessToken()
            }
        });

        //let dd = new Date(element.timestamp)
        //console.log(dd)
        //console.log(element.timestamp)
        //console.log(Dates.getDates(element.timestamp))
        //console.log(Dates.getTime(element.timestamp))

        let _times = element.startTime || element.timestamp

        let _diff = Dates.getDiffTime(_times, new Date())
        let _timepass = ''
        if(_diff.day>0){
            _timepass = _diff.day + " Days Ago"
        }
        else if(_diff.hour>0){
            _timepass = _diff.hour + " Hours Ago"
        }
        else if(_diff.minute>0){
            _timepass = _diff.minute + " Minutes Ago"
        }
        else if(_diff.second>0){
            _timepass = Math.round(_diff.second) + " Seconds Ago"
        }
        // let _timedate = Dates.getTime(element.timestamp) + ' | ' + Dates.getMonthName(element.timestamp) + ' ' + Dates.getDateNumber(element.timestamp)+', '+Dates.getYear(element.timestamp)
        let _timedate = Dates.getTime(_times) + ' | ' + Dates.getMonthName(_times) + ' ' + Dates.getDateNumber(_times)+', '+Dates.getYear(_times)
        
        // console.log(_timedate)
        //10 Hours Ago
        //15:13:37 | Jan 1, 2015

        if(typeof(element.pointBlock)==='undefined'){
            element.pointBlock = "Starting Point"
        }
        if(typeof(element.pointBangunan)==='undefined'){
            element.pointBangunan = ""
        }

        allresult.push({
            block:element.pointBlock,
            bangunan:element.pointBangunan,
            weight:element.afterWeight,
            datetime:element.timestamp,

            timepass:_timepass,
            timedate:_timedate,

            image_lorry:pp_image_lorry,
            image_before:pp_image_before,
            image_after:pp_image_after,
            image_resit:pp_image_resit
        })
    });

    return allresult
}
