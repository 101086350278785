import { CONSTANTS } from '../_constants';
let initState = {data:[]}

export function vhistory(state = initState, action) {
  switch (action.type) {
    case CONSTANTS.JOBHISTORY.REQUEST:
      return {
        request:true
      };
    case CONSTANTS.JOBHISTORY.SUCCESS:
      return {
        data:action.result
      }
    case CONSTANTS.JOBHISTORY.FAILURE:
      return {
        message: action.message
      };
    case CONSTANTS.CLEAR:
        return initState
    default:
      return state
  }
}