import { Post,PostQuery,GetQuery,Patch } from '../../_attfunc';
//import Dates from '../../_attfunc/dates'
import { SERVER, CONSTANTS } from '../../_constants'

class AppUser {
    login(username, password) {
        //console.log('=========user.action: login()===============')
        return PostQuery(SERVER.API.AppUser.Login,{ username:username, password:password },{include:['user']})
    }
    logout() {
        //console.log('=========AppUser: logout()===============');
        return Post(SERVER.API.AppUser.Logout)
    }

    registerUserUtm(param){
        return this.registerUser(SERVER.API.AppUser.RegisterUtm, param)
    }
    registerUserContractor(param){
        return this.registerUser(SERVER.API.AppUser.RegisterContractor, param)
    }
    registerUserWorker(param){
        return this.registerUser(SERVER.API.AppUser.RegisterWorker, param)
    }
    registerUser(url, param) {
        //console.log('=========AppUser: registerUser()===============');
        return Post(url,param)
    }
    updateUser(id, param){
        //console.log('=========AppUser: updateUser()===============');
        // return Patch(SERVER.API.AppUser.Users+'/'+id,param)
        param.id = id
        return Post(SERVER.API.AppUser.Updates, param)
    }
    findUser(filter){
        //console.log('=========user.action: findUser()===============');
        return GetQuery(SERVER.API.AppUser.Users,filter)
    }
    deleteUser(id){
        //console.log('=========user.action: deleteUser()===============');
        return Patch(SERVER.API.AppUser.Users+'/'+id+'/unregister')
    }

    getListUtm(){
        //console.log('=========AppUser: getListUtm()===============');
        return new Promise(function(resolve, reject){
            GetQuery(SERVER.API.AppUser.ListUtm).then(result=>{
                let _arr = []
                result.forEach(element => {
                    _arr.push({
                        id: element.id,
                        name: element.name,
                        username: element.username,
                        email: element.email,
                        role: CONSTANTS.ROLE.UTM
                    })
                });
                return resolve(_arr)
            },err=>{
                return reject(err)
            })
        })
        //return GetQuery(SERVER.API.AppUser.ListUtm)
    }
    getListContractor(){
        //console.log('=========AppUser: getListContractor()===============');
        return new Promise(function(resolve, reject){
            GetQuery(SERVER.API.AppUser.ListContractor).then(result=>{
                let _arr = []
                result.forEach(element => {
                    _arr.push({
                        id:element.id,
                        name:element.name,
                        username: element.username,
                        email: element.email,
                        role: CONSTANTS.ROLE.CONTRACTOR
                    })
                });
                return resolve(_arr)
            },err=>{
                return reject(err)
            })
        })
        //return GetQuery(SERVER.API.AppUser.ListContractor)
    }
    getListWorker(company){
        //console.log('=========AppUser: getListWorker()===============');
        return new Promise(function(resolve, reject){
            if(typeof(company)==='undefined' || company === null){
                return reject([])
            }
            GetQuery(SERVER.API.AppUser.ListWorker).then(result=>{
                //console.log(result)
                let _arr = []
                if(company === 'all'){
                    result.forEach(element => {
                        _arr.push({
                             id:element.id,
                            name:element.name,
                            username: element.username,
                            email: element.email,
                            role: CONSTANTS.ROLE.WORKER
                        })
                    });
                }
                else{
                    result.forEach(element => {
                        if(element.company === company){
                            _arr.push({
                                id:element.id,
                                name:element.name,
                                username: element.username,
                                email: element.email,
                                role:CONSTANTS.ROLE.WORKER
                            })
                        }
                    });
                }
                
                return resolve(_arr)
            },err=>{
                return reject(err)
            })
        })
        //return GetQuery(SERVER.API.AppUser.ListWorker)
    }

    changePassword(param){
        return Post(SERVER.API.AppUser.ChangePassword,param)
    }
    resetPassword(param){
        return Post(SERVER.API.AppUser.ResetPassword,param)
    }
}

export default AppUser;
