import React, { Component } from 'react';
import {
    Card,
    CardBody,
    Col,
    Row,
  } from 'reactstrap';

class ResponseView extends Component {
    render() {
        var back_color = this.props.status ? 'bg-primary' : 'bg-danger'
        var content_center = this.props.center ? 'justify-content-center' : ''
        return (
            <Row className={content_center}>
                <Col xs="12" md="6">
                    <Card className={`text-white ${back_color} text-center`}>
                        <CardBody>
                            {this.props.message}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default ResponseView
