import { CONSTANTS } from '../_constants'
import Dates from '../_attfunc/dates'
import Company from '../_models/company'

const _Company = new Company()

export const companyActions = {
    register,
    update,
    softDelete,
    getList,
    updateById
};

// ============= state users =================
function register(param, dispatch){
    if(typeof(dispatch)!=='undefined' && dispatch!== null){
        _Company.register(param).then(result=>{
            dispatch({ type: CONSTANTS.COMPANY.REGISTER_SUCCESS, result: result});
        }, error=>{
            dispatch({ type: CONSTANTS.COMPANY.REGISTER_FAILURE, message: error });
        })
    }
    else{
        return _Company.register(param)
    }
}
function update(param, dispatch){  
    if(typeof(dispatch)!=='undefined' && dispatch!== null){
        _Company.update(param).then(result=>{
            dispatch({ type: CONSTANTS.COMPANY.UPDATE_SUCCESS, result: result});
        }, error=>{
            dispatch({ type: CONSTANTS.COMPANY.UPDATE_FAILURE, message: error });
        })
    }
    else{
        return _Company.update(param)
    }
}
function updateById(id, param, dispatch){
    if(typeof(dispatch)!=='undefined' && dispatch!== null){
        _Company.updateById(id, param).then(result=>{
            dispatch({ type: CONSTANTS.COMPANY.UPDATE_SUCCESS, result: result});
        }, error=>{
            dispatch({ type: CONSTANTS.COMPANY.UPDATE_FAILURE, message: error });
        })
    }
    else{
        return _Company.updateById(id, param)
    }
}
function softDelete(id, modifiedBy, dispatch){
    if(typeof(dispatch)!=='undefined' && dispatch!== null){
        _Company.softDelete(id, modifiedBy).then(result=>{
            dispatch({ type: CONSTANTS.COMPANY.DELETE_SUCCESS, result: result});
        }, error=>{
            dispatch({ type: CONSTANTS.COMPANY.DELETE_FAILURE, message: error });
        })
    }
    else{
        return _Company.softDelete(id, modifiedBy)
    }
}
function getList(dispatch){
    let param = {
        where: {
            isDeleted: false
        }
    }
    if(typeof(dispatch)!=='undefined' && dispatch!== null){
        _Company.getList(param).then(result=>{
            for (let index = 0; index < result.length; index++) {
                result[index].createdDate = Dates.getDateTimeWithFormat(result[index].createdDate, CONSTANTS.DATE_FORMAT.DATE_TIME)
            }

            dispatch({ type: CONSTANTS.COMPANY.LIST_SUCCESS, result: result});
        }, error=>{
            dispatch({ type: CONSTANTS.COMPANY.LIST_FAILURE, message: error });
        })
    }
    else{
        return _Company.getList(param)
    }
}

