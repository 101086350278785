import { CONSTANTS } from '../_constants'
import { saveAuthUser, removeAuthUser } from '../_attfunc';
import Dates from '../_attfunc/dates'

import AppUser from '../_models/appuser'
const _AppUser  = new AppUser()

export const AuthorizationActions = {
    login,
    logout
};

function login(username, password, history, dispatch) {
    //console.log('=========user.action: login()===============')
    if(typeof(dispatch)!=='undefined' && dispatch!== null){
        _AppUser.login(username, password).then(result=>{
            if(result.role.toString().toUpperCase() === CONSTANTS.ROLE.WORKER){
                dispatch({ type: CONSTANTS.AUTHORIZATION.LOGIN_FAILURE, message: 'This User cannot be access the page.' });
                return
            }
            let res = {
                id:result.id,
                uid:result.userId,
                name: result.user.name,
                email: result.user.email,
                company: result.user.company,
                companyId: result.user.companyId,
                category: result.user.category,
                role: result.role.toString().toUpperCase(),
                created: Dates.getDateTime(result.user.created)
            }
            saveAuthUser(res)

            dispatch({ type: CONSTANTS.AUTHORIZATION.LOGIN_SUCCESS, res });
            history.push('/')
        },err=>{
            dispatch({ type: CONSTANTS.AUTHORIZATION.LOGIN_FAILURE, message: 'Fail to Login.' });
        })
    }
    else{
        return _AppUser.login(username, password)
    }
}
function logout(history, dispatch) {
    //console.log('=========user.action: logout()===============');
    //console.log(history)
    if(typeof(dispatch)!=='undefined' && dispatch!== null){
        _AppUser.logout().then(result=>{
            removeAuthUser()
            dispatch({ type: CONSTANTS.AUTHORIZATION.LOGOUT_SUCCESS });
            dispatch({ type: CONSTANTS.CLEAR })
            history.push('/')
        },err=>{
            removeAuthUser()
            dispatch({ type: CONSTANTS.AUTHORIZATION.LOGOUT_FAILURE, message:err });
            dispatch({ type: CONSTANTS.CLEAR })
            history.push('/')
        })
    }
    else{
        return _AppUser.logout()
    }
}
