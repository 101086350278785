// import { SERVER } from '../_constants';
//import { Post,PostQuery,Patch,Get,GetQuery } from '../_attfunc';
// import Dates from '../_attfunc/dates'

import PickupPoint from '../_models/pickuppoint'

import { CONSTANTS } from '../_constants'
// import { getAuthUserAccessToken } from '../_attfunc/auth'


const _PickupPoint = new PickupPoint()

export const LocationPickupPointActions = {
    getLocation,
    register,
    update,
    deletes
};

async function getLocation(dispatch){
    let _result = await _PickupPoint.getPickupPoint()

    if(typeof(dispatch)!=='undefined' && dispatch!== null){
        dispatch({ type: CONSTANTS.PICKUP_POINT.SUCCESS, _result })
    }
    else{
        return Promise.resolve(_result)
    }
}
function register(param){
    return _PickupPoint.register(param)
}
function update(id, param){
    return _PickupPoint.update(id, param)
}
function deletes(id){
    return _PickupPoint.deletes(id)
}



