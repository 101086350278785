import { CONSTANTS } from '../_constants';
let initState = {data:[]}

export function dashboard(state = initState, action) {
  switch (action.type) {
    case CONSTANTS.DASHBOARD.REQUEST:
      return {
        request:true
      };
    case CONSTANTS.DASHBOARD.SUCCESS:
      return {
        tick:action.result.tick,
        data:{
          [action.result.tick]:action.result.data, ...state.data
        }

        // [{[__b]:1}]
      }
    case CONSTANTS.DASHBOARD.FAILURE:
      return {
        message: action.message
      };
    case CONSTANTS.DASHBOARD.VIEW:
      return {
        tick: action.tick,
        data: {...state.data}
      }
    case CONSTANTS.CLEAR:
      return initState
    default:
      return state
  }
}